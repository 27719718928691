import React, { useRef, useState, useEffect } from "react";
import { setStorage, getStorage, deleteStorage } from "../modals/Storage";
import {
  API_ACCESS_URL,
  AVATARS_ARR,
  getRandomInt,
  openNewPage,
} from "../modals/Constants";
import { Link, useNavigate } from "react-router-dom";
import BottomNavbar from "../other-components/BottomNavbar/Navbar";
import NoticeDialog from "../dialogs/NoticeDialog2";
import SlideShow from "../other-components/SlideShow";
import styles from "../css/Screens.module.css";
import general from "../css/General.module.css";
import ImportantNotice from "../other-components/ImportantNotice/Notice";

function Home() {
  const navigate = useNavigate();
  const [isLoadingShow, setLoadingShow] = useState(false);
  const [viewBoxNumber, setViewBoxNumber] = useState(0);

  const [pageConst, setConstants] = useState({
    accountId: "",
    accountBalance: 0,
    isDataLoaded: false,
    recordList: [],
    sliderImages: [],
    winnersRecordList: [],
  });

  const [noticeDialogConst, setNoticeDialogConst] = useState({
    noticeTitle: "",
    noticeNote: "",
    noticeDialogShow: false,
  });

  const refreshData = () => {
    setConstants((previousState) => {
      return { ...previousState, isDataLoaded: false };
    });
  };

  const updateSliderImagesArr = (data) => {
    if (data.length > 0) {
      setConstants((previousState) => {
        return { ...previousState, sliderImages: data };
      });
    }
  };

  const showNotice = (data) => {
    if (data.length > 0) {
      setNoticeDialogConst((previousState) => {
        return { ...previousState, noticeTitle: data[0]["notice_title"] };
      });

      setNoticeDialogConst((previousState) => {
        return { ...previousState, noticeNote: data[0]["notice_note"] };
      });

      setNoticeDialogConst((previousState) => {
        return { ...previousState, noticeDialogShow: true };
      });
    }
  };

  const updateNoticeDialogState = (data) => {
    setNoticeDialogConst((previousState) => {
      return { ...previousState, noticeDialogShow: data };
    });
  };

  function getAccountInfo(accountId, accountAuthSecret) {
    const fecthApiData = async (url, authSecret) => {
      try {
        const res = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Route: "route-account-info",
            AuthToken: authSecret,
          },
        });
        const data = await res.json();

        setLoadingShow(false);

        if (data.status_code == "success") {
          let accountAvatarId = data.data[0]["account_avatar_id"];
          let accountUserName = data.data[0]["account_user_name"];
          let accountBalance = data.data[0]["account_balance"];
          let accountWinningBalance = data.data[0]["account_w_balance"];
          let accountCommissionBalance = data.data[0]["account_c_balance"];
          let accountLevel = data.data[0]["account_level"];
          let accountLastActive = data.data[0]["account_last_active"];

          let service_app_status = data.data[0]["service_app_status"];
          let service_min_recharge = data.data[0]["service_min_recharge"];
          let service_min_withdraw = data.data[0]["service_min_withdraw"];
          let service_recharge_option = data.data[0]["service_recharge_option"];
          let service_telegram_url = data.data[0]["service_telegram_url"];
          let service_payment_url = data.data[0]["service_payment_url"];
          let service_livechat_url = data.data[0]["service_livechat_url"];
          let service_imp_message = data.data[0]["service_imp_message"];
          let service_app_download_url =
            data.data[0]["service_app_download_url"];

          setStorage("avatarId", accountAvatarId);
          setStorage("uname", accountUserName);
          setStorage("balance", accountBalance);
          setStorage("cbalance", accountCommissionBalance);
          setStorage("wbalance", accountWinningBalance);
          setStorage("wbalance", accountWinningBalance);
          setStorage("accountLevel", accountLevel);
          setStorage("accountLastActive", accountLastActive);
          setStorage("minwithdraw", service_min_withdraw);
          setStorage("minrecharge", service_min_recharge);
          setStorage("rechargeoptions", service_recharge_option);
          setStorage("telegramURL", service_telegram_url);
          setStorage("liveChatURL", service_livechat_url);
          setStorage("paymentURL", service_payment_url);
          setStorage("impMessage", service_imp_message);
          setStorage("appDownloadURL", service_app_download_url);
          // setStorage("winnersArr", JSON.stringify(data.winnersArr));
          setConstants({
            ...pageConst,
            accountBalance: accountBalance,
            isDataLoaded: true,
          });

          if (service_app_status == "OFF") {
            navigate("/um", { replace: true });
          } else {
            updateWinnersList(data.winnersArr);
            showNotice(data.noticeArr);
            updateSliderImagesArr(data.slideShowList);
          }
        } else if (data.status_code == "authorization_error") {
          if (deleteStorage()) {
            navigate("/login", { replace: true });
          }
        }
      } catch (error) {
        setLoadingShow(false);
      }
    };

    if (!isLoadingShow && !pageConst.isDataLoaded) {
      setLoadingShow(true);
      fecthApiData(API_ACCESS_URL + "?USER_ID=" + accountId, accountAuthSecret);
    }
  }

  const getRandomNum = (min, max, type) => {
    if (type == "multi") {
      return Math.round((Math.random() * (max - min) + min) / 10) * 10;
    } else {
      return Math.floor(Math.random() * (max - min) + min);
    }
  };

  const getRandBool = () => {
    let returnVal = false;

    let status = Math.round(Math.random());
    if (status == 1) {
      returnVal = true;
    }

    return returnVal;
  };

  const updateWinnersList = (data) => {
    let tempData = [];

    tempData.push(
      <div key={61}>
        <div className={[styles.winnersListStageView]}>
          <div className="col-view v-center mg-t--45">
            <div className="ps-rl">
              <img
                src={AVATARS_ARR[Number(data[1]["avatar_id"])]["avatarURL"]}
                className="h-w-60 br-50"
                alt="icon"
              />
              <img
                src={require("../media/icons/crown_no2_icon.png")}
                className="ps-ab ps-tp ps-lf--5 h-w-36 mg-t--20"
                alt="icon"
              />
            </div>

            <img
              src={require("../media/icons/win_no2_icon.png")}
              className="h-18-p z-i-1 mg-t--10"
              alt="icon"
            />
            <p className="ft-sz-14 cl-white mg-t-15">{data[1]["mobile_num"]}</p>
            <div className="ft-sz-12 pd-5-10 mg-t-5 cl-white br-15 bg-l-white-2">
              ₹{data[1]["winning_amount"]}
            </div>
          </div>

          <div className="col-view v-center mg-t--90">
            <div className="ps-rl">
              <img
                src={AVATARS_ARR[Number(data[0]["avatar_id"])]["avatarURL"]}
                className="h-w-60 br-50"
                alt="icon"
              />
              <img
                src={require("../media/icons/crown_no1_icon.png")}
                className="ps-ab ps-tp ps-lf--5 h-w-36 mg-t--20"
                alt="icon"
              />
            </div>

            <img
              src={require("../media/icons/win_no1_icon.png")}
              className="h-18-p z-i-1 mg-t--10"
              alt="icon"
            />
            <p className="ft-sz-14 cl-white mg-t-15">{data[0]["mobile_num"]}</p>
            <div className="ft-sz-12 pd-5-10 mg-t-5 cl-white br-15 bg-l-white-2">
              ₹{data[0]["winning_amount"]}
            </div>
          </div>

          <div className="col-view v-center mg-t--45">
            <div className="ps-rl">
              <img
                src={AVATARS_ARR[Number(data[2]["avatar_id"])]["avatarURL"]}
                className="h-w-60 br-50"
                alt="icon"
              />
              <img
                src={require("../media/icons/crown_no3_icon.png")}
                className="ps-ab ps-tp ps-lf--5 h-w-36 mg-t--20"
                alt="icon"
              />
            </div>

            <img
              src={require("../media/icons/win_no3_icon.png")}
              className="h-18-p z-i-1 mg-t--10"
              alt="icon"
            />
            <p className="ft-sz-14 cl-white mg-t-15">{data[2]["mobile_num"]}</p>
            <div className="ft-sz-12 pd-5-10 mg-t-5 cl-white br-15 bg-l-white-2">
              ₹{data[2]["winning_amount"]}
            </div>
          </div>
        </div>
      </div>
    );

    for (let i = 3; i < data.length; i++) {
      tempData.push(
        <div
          key={i}
          className={[
            general.rowView,
            general.spaceBetween,
            styles.winnerPersonView,
            i > 3 ? general.mgT10px : "",
          ].join(" ")}
        >
          <div className="row-view">
            <span className={[general.fontS20Px, general.width20px].join(" ")}>
              {i + 1}
            </span>

            <div className="h-w-45 ovf-hidden br-50 mg-l-40">
              <img
                src={AVATARS_ARR[Number(data[i]["avatar_id"])]["avatarURL"]}
                className="h-w-45"
                alt="icon"
              />
            </div>
            <span className={[general.fontS16Px, general.mgL10px].join(" ")}>
              {data[i]["mobile_num"]}
            </span>
          </div>
          <span className={[styles.winnerWonAmountView]}>
            ₹{data[i]["winning_amount"]}
          </span>
        </div>
      );
    }

    setConstants((previousState) => {
      return { ...previousState, winnersRecordList: tempData };
    });
  };

  const openGame = (url) => {
    if (getStorage("uid")) {
      navigate("/" + url, { replace: false });
    } else {
      redirectToLogin();
    }
  };

  const redirectToLogin = () => {
    deleteStorage();
    navigate("/login", { replace: true });
  };

  useEffect(() => {
    let accountId = "";
    let accountAuthSecret = "";

    if (getStorage("uid")) {
      accountId = getStorage("uid");
      accountAuthSecret = getStorage("secret");
    } else {
      accountId = "guest";
      accountAuthSecret = "guest";
    }

    getAccountInfo(accountId, accountAuthSecret);
  }, [pageConst.sliderImages, pageConst.winnersList]);

  return (
    <div className={[general.viewCenter, general.appBackground].join(" ")}>
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.overflowScrollY,
          general.appContentsBackground,
          general.hideScrollBar,
        ].join(" ")}
      >
        <NoticeDialog
          intentData={noticeDialogConst}
          updateState={updateNoticeDialogState}
        />

        <div className={[styles.homeView, general.posRelative].join(" ")}>
          <div
            className={[
              styles.topBar,
              general.rowView,
              general.spaceBetween,
              general.width100,
              general.appContentsBackground,
            ].join(" ")}
          >
            <img
              className={`h-40-p mg-l-10`}
              src={require("../media/icons/app_logo_fill.png")}
              alt="icon"
            />

            <div className={[!getStorage("uid") ? general.hideView : ""]}>
              <img
                className={`h-25-p`}
                src={require("../media/icons/live_support_icon.png")}
                alt="icon"
                onClick={() => openNewPage(getStorage("liveChatURL"))}
              />
              <img
                className={`h-25-p mg-l-15`}
                src={require("../media/icons/download_icon.png")}
                alt="icon"
                onClick={() => openNewPage(getStorage("appDownloadURL"))}
              />
            </div>

            <div
              className={[
                general.rowView,
                !getStorage("uid") ? "" : general.hideView,
              ].join(" ")}
            >
              <Link
                className={[
                  general.textDecoNone,
                  general.height30px,
                  general.viewCenter,
                  general.colorWhite,
                  general.fontS14Px,
                  general.pd5px15px,
                  general.borderRadius,
                  general.bgCustomColor3,
                ].join(" ")}
                to={"/login"}
              >
                Login
              </Link>

              <Link
                className={[
                  general.textDecoNone,
                  general.height30px,
                  general.viewCenter,
                  general.colorWhite,
                  general.fontS14Px,
                  general.pd5px15px,
                  general.mgL10px,
                  general.borderRadius,
                  general.bgCustomColor2,
                ].join(" ")}
                to={"/register"}
              >
                Register
              </Link>
            </div>
          </div>

          <div className={[general.pd15px]}>
            <SlideShow dataList={pageConst.sliderImages} />
          </div>

          <div className="pd-0-15">
            <ImportantNotice />
          </div>

          <div
            className={[
              styles.homeAdditionalGamesTitle,
              viewBoxNumber != 0 ? styles.hideView : "",
            ].join(" ")}
          >
            <span></span>
            Lottery
          </div>

          <div
            className={[
              general.gridView2,
              general.gridGap2,
              styles.pd15px,
              viewBoxNumber != 0 ? styles.hideView : "",
            ].join(" ")}
          >
            <div
              className="ps-rl txt-deco-n ovf-hidden br-10 bg-white"
              onClick={() => openGame("wingo1min")}
            >
              <img
                className="w-100"
                src={require("../icons/wingo_banner.png")}
              />

              <div className="col-view pd-10-15 br-10 bg-white">
                <div className="row-view">
                  <div className="h-w-32 ovf-hidden br-50">
                    <img
                      src={AVATARS_ARR[getRandomInt(0, 7)]["avatarURL"]}
                      className="h-w-32"
                      alt="icon"
                    />
                  </div>

                  <span className="ft-sz-13 cl-drk-black mg-l-10">
                    *****{getRandomInt(100, 999)}
                  </span>
                </div>

                <span className="ft-sz-13 cl-drk-black mg-t-10">
                  Won Amount: ₹{getRandomInt(2, 1600).toFixed(2)}
                </span>
              </div>
            </div>

            <div
              className="ps-rl txt-deco-n ovf-hidden br-10 bg-white"
              onClick={() => openGame("k3lottery1min")}
            >
              <img
                className="w-100"
                src={require("../icons/k3lottery_banner.png")}
              />

              <div className="col-view pd-10-15 br-10 bg-white">
                <div className="row-view">
                  <div className="h-w-32 ovf-hidden br-50">
                    <img
                      src={AVATARS_ARR[getRandomInt(0, 7)]["avatarURL"]}
                      className="h-w-32"
                      alt="icon"
                    />
                  </div>

                  <span className="ft-sz-13 cl-drk-black mg-l-10">
                    *****{getRandomInt(100, 999)}
                  </span>
                </div>

                <span className="ft-sz-13 cl-drk-black mg-t-10">
                  Won Amount: ₹{getRandomInt(2, 1600).toFixed(2)}
                </span>
              </div>
            </div>

            <div
              className="ps-rl txt-deco-n ovf-hidden br-10 bg-white"
              onClick={() => openGame("AndarBahar")}
            >
              {/* <div className={[styles.homeGameViewBox]}></div> */}
              <img
                className="w-100"
                src={require("../icons/andar_bahar_banner.jpg")}
              />
              {/* <div className="col-view ps-ab ps-lf ps-tp pd-15">
                <span className="ft-sz-23 ft-wgt-500 cl-white">
                  Andar Bahar
                </span>
                <span className="ft-sz-12 cl-white mg-t-10">Guess Number</span>
                <span className="ft-sz-12 cl-white">
                  Green/Red/Purple to win
                </span>
              </div> */}

              <div className="col-view sb-view pd-10-15 br-10 bg-white">
                <div className="row-view">
                  <div className="h-w-32 ovf-hidden br-50">
                    <img
                      src={AVATARS_ARR[getRandomInt(0, 7)]["avatarURL"]}
                      className="h-w-32"
                      alt="icon"
                    />
                  </div>

                  <span className="ft-sz-13 cl-drk-black mg-l-10">
                    *****{getRandomInt(100, 999)}
                  </span>
                </div>

                <span className="ft-sz-13 cl-drk-black mg-t-10">
                  Won Amount: ₹{getRandomInt(2, 1600).toFixed(2)}
                </span>
              </div>
            </div>

            <div
              className="ps-rl txt-deco-n ovf-hidden br-10 bg-white"
              onClick={() => openGame("GoldMiner")}
            >
              <img
                className="w-100"
                src={require("../icons/goldminer_banner.png")}
              />

              <div className="col-view sb-view pd-10-15 br-10 bg-white">
                <div className="row-view">
                  <div className="h-w-32 ovf-hidden br-50">
                    <img
                      src={AVATARS_ARR[getRandomInt(0, 7)]["avatarURL"]}
                      className="h-w-32"
                      alt="icon"
                    />
                  </div>

                  <span className="ft-sz-13 cl-drk-black mg-l-10">
                    *****{getRandomInt(100, 999)}
                  </span>
                </div>

                <span className="ft-sz-13 cl-drk-black mg-t-10">
                  Won Amount: ₹{getRandomInt(2, 1600).toFixed(2)}
                </span>
              </div>
            </div>

            <div
              className="ps-rl txt-deco-n ovf-hidden br-10 bg-white"
              onClick={() => openGame("Wheelocity")}
            >
              <img
                className="w-100"
                src={require("../icons/wheelocity_banner.png")}
              />

              <div className="col-view sb-view pd-10-15 br-10 bg-white">
                <div className="row-view">
                  <div className="h-w-32 ovf-hidden br-50">
                    <img
                      src={AVATARS_ARR[getRandomInt(0, 7)]["avatarURL"]}
                      className="h-w-32"
                      alt="icon"
                    />
                  </div>

                  <span className="ft-sz-13 cl-drk-black mg-l-10">
                    *****{getRandomInt(100, 999)}
                  </span>
                </div>

                <span className="ft-sz-13 cl-drk-black mg-t-10">
                  Won Amount: {getRandomInt(2, 1600).toFixed(2)}
                </span>
              </div>
            </div>
          </div>

          <div className="min-h pd-15 mg-t-20 mg-b-10em">
            <span>Top Earner's List</span>

            <div className="col-view">{pageConst.winnersRecordList}</div>
          </div>
        </div>

        <BottomNavbar activeBar="home" />
      </div>
    </div>
  );
}

export default Home;
